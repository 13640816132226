import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import  { DeleteModal } from '../../components/Modal';
import CoinsAPI from '../../api/coins';
import { withToast } from '../../api/util';
import { X } from 'react-bootstrap-icons';

const DisplayImages = (props) => {
  const { images, onRefresh } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const { t } = useTranslation();

  const deleteCoinTypeImage = async(id) =>  {
    setShowDeleteModal(false);
    try {
      withToast(await CoinsAPI.deleteCoinTypeImage(id));
      onRefresh();
    } catch (err) {
      console.error(err);
    }
  }

  return(
    <Row>
      {images?.map((image, i) => {
        return (
          <Col lg={4} key={i}>
            <div style={{backgroundColor: "#EEEEEE", padding: "5px", marginBottom: "5px"}}>
            <X className="custom-icon float-end" color="red" size={20}
              onClick={() => {setShowDeleteModal(true), setObjToDelete(image.id)}}
            />
            <div style={{
                      width: "100%",
                      height: "20vh",
                      marginTop:"20px",
                      marginBottom: "10px",
                    }}>
              <img
                style={{
                height: "100%",
                width: "100%",
                objectFit: "contain"
              }}
              src={image.picture} />
            </div>
            <div>
              {image.caption}
            </div>
          </div>
          </Col>
        )
      })}
      {showDeleteModal && <DeleteModal
        onOK={() => deleteCoinTypeImage(objToDelete)}
        onCancel={() => setShowDeleteModal(false)}
        dialogClassName={'second-dialog modal-w70'}
      />}
    </Row>
  )
  

}
export default DisplayImages;
