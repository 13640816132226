import React from "react";
import { useTranslation } from "react-i18next"
import { Field } from "formik";
import { Row, Col } from "react-bootstrap";
import { MAX_FILE_SIZE_DISPLAY, TOTAL_FILE_SIZE_DISPLAY } from "../containers/constants";

export const FileBrowser = ({title, onChange, filename, small, disabled, name, acceptedType, ...rest}) => {
    const { t } = useTranslation();
    return(
      <Field name={name}>
      {({ field, form, meta }) => {
        return (
        <div className="form-group">
            {title && <label>{title}</label>}
            <div className="input-group">
                <label className="input-group-prepend">
                    <span className={disabled ? "btn custom-button btn-primary disbled" : "btn btn-primary custom-button"} style={{zIndex: "0"}}>
                        {t('buttons.browse')}<input type="file" name='fileName' style={{display: "none"}} onChange={onChange} accept={acceptedType}
                        onClick={() => null}/>
                    </span>
                </label>
                <input
                    name="fileTemplate"
                    type="text"
                    className="form-control"
                    value={filename ? filename : ''}
                    readOnly
                    required
                    style={{height: '33px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
                    {...rest}>
                </input>
                {meta.touched  && meta.error && (
                  <div className="invalid-feedback d-block">{meta.error}</div>
                )}
            </div>
        </div>)
      }}
    </Field>
  )
}

export const ImageBrowser = ({title, onChange, filename, error, small, preview, disabled, name,...rest}) => {
    const { t } = useTranslation();
    return (
      <Field name={name}>
          {({ field, form, meta }) => {
            return(
              <div className="form-group">
                  {title && <label>{title}</label>}
                  <div className="input-group">
                      <label className="input-group-prepend">
                          <span className={disabled ? "btn custom-button btn-primary disbled" : "btn btn-primary custom-button"} style={{zIndex: "0"}}>
                              {t('buttons.browse')}
                                <input
                                  type="file"
                                  accept="image/*"
                                  name='fileName'
                                  style={{display: "none"}}
                                  onChange={onChange}
                                  onClick={() => null}
                                />
                          </span>
                      </label>
                      <input
                          data-testid='fileTemplate'
                          name="fileTemplate"
                          type="text"
                          className="form-control"
                          value={filename ? filename : ''}
                          readOnly
                          required
                          style={{height: '33px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
                          {...rest}>
                      </input>
                      <div className='d-block' style={{width: '100%'}}>
                        <small>{t('validation.max_file_size', {max: MAX_FILE_SIZE_DISPLAY})}</small>
                      </div>
                      {meta.touched  && meta.error && (
                        <div className="invalid-feedback d-block">{meta.error}</div>
                      )}
                      {filename && preview &&
                        <div style={{
                          width: "100%",
                          height: "20vh",
                          marginTop:"20px",
                          marginBottom: "10px",
                        }}>
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain"
                          }}
                          src={meta.value?.name ? URL.createObjectURL(meta.value)  : filename} />
                        </div>
                      }
                  </div>
              </div>)
          }}
      </Field>
    )
}


export const MultipleImageBrowser = ({title, onChange, files, error, small, preview, disabled, name,...rest}) => {
  const { t } = useTranslation();
  return (
    <Field name={name}>
        {({ field, form, meta }) => {
          const isValid = !meta.error;
          const isInvalid = meta.touched && !isValid;
          return(
            <div className="form-group">
                {title && <label>{title}</label>}
                <div className="input-group">
                    <label className="input-group-prepend">
                        <span className={disabled ? "btn custom-button btn-primary disbled" : "btn btn-primary custom-button"} style={{zIndex: "0"}}>
                          {t('buttons.browse')}
                            <input
                              type="file"
                              accept="image/*"
                              name='files[]'
                              style={{display: "none"}}
                              onChange={onChange}
                              onClick={() => null}
                              multiple
                            />
                        </span>
                    </label>
                      <input
                        name="fileTemplate"
                        type="text"
                        className="form-control"
                        value={t('checkpointImages', {count: files?.length})}
                        readOnly
                        required
                        style={{height: '33px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
                        {...rest}>
                      </input>
                      <div className='d-block' style={{width: '100%'}}>
                        <small>{t('validation.exceed_max_upload_size', {max: TOTAL_FILE_SIZE_DISPLAY})}</small>
                      </div>
                    {isInvalid && (
                      <div className="invalid-feedback d-block">{meta.error}</div>
                    )}
                </div>
            </div>)
        }}
    </Field>
  )
}

export const ImageBrowserInline = ({onChange, filename, name,...rest}) => {
  const { t } = useTranslation();
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const isValid = !meta.error;
        const isInvalid = meta.touched && !isValid;
        return(
          <Row>
            <Col lg={8} className="align-self-center justify-content-center">
              <div className="input-group">
                  <label className="input-group-prepend">
                    <div className={"btn btn-primary custom-button"} style={{zIndex: "0"}}>
                      {t('buttons.browse')}
                        <input
                          type="file"
                          accept="image/*"
                          name='fileName'
                          style={{display: "none"}}
                          onChange={onChange}
                          onClick={() => null}
                        />
                    </div>
                  </label>
                  <input
                    name="fileTemplate"
                    type="text"
                    className="form-control"
                    value={filename ? filename : ''}
                    readOnly
                    required
                    style={{height: '33px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
                    {...rest}>
                  </input>
                  {isInvalid && (
                    <div className="invalid-feedback d-block">{meta.error}</div>
                  )}
              </div>
            </Col>
            <Col lg={4} className="align-self-center justify-content-center">
              <div style={{
                  width: "100%",
                  height: "6vh",
                }}>
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain"
                  }}
                  src={meta.value?.name ? URL.createObjectURL(meta.value) : filename} />
              </div>
            </Col>
          </Row>
        )
      }}
    </Field>
  )
}
