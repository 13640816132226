import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Button, Row,Tabs, Tab, Textarea} from 'react-bootstrap';
import InfoContentAPIs from '../../api/infoContent';
import CustomModal from '../../components/Modal';
import { FormCheckbox, FormEditor, FormSelectField, FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from "yup";
import APIs from '../../api/apiCalls';
import { checkFileSize, MAX_FILE_SIZE_DISPLAY, statusOptions } from '../constants';
import {ImageBrowser} from '../../components/FileBrowser'
import { X } from 'react-bootstrap-icons';

const InfoContentDetails = (props) => {
  const { selectedRow, onCancel, onCreate, language, onEdit } = props;
  const [infoContentTmp, setInfoContentTmp] = useState(null);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();
  const formRef = useRef()

  const initialState = {
    name: '',
    title: '',
    picture: '',
    short_intro: '',
    tags: [],
    qr_code: '',
    status: "development",
    content: '',
    external: false,
    external_url: '',
    show_in_browser: false,
    average_rating: null,
    send_notifications: false,
  }

  const schema = yup.object({
    status: yup.string().required(t('validation.is_required', {obj: t('status')})),
    title: yup.string().max(50).required(t('validation.is_required', {obj: t('title')})),
    short_intro: yup.string()
    .max(256)
    .required(t('validation.is_required', {obj: t('short_intro')})),
    tags: yup.array().required(t('validation.is_required', {obj: t('tags')})),
    content: yup.string().when('external', {
      is: false,
      then: schema => schema.required(t('validation.is_required', {obj: t('content')})),
      otherwise: schema => schema.optional()
    }),
    external: yup.boolean(),
    external_url: yup.string().when('external', {
      is: true,
      then:  schema => schema
      .url(t('validation.valid_url'))
      .required(t('validation.is_required', {obj: t('external_url')}))
    }),
    picture: yup.mixed().test(
      'is-valid-size',
      t('validation.max_file_size_error', { max: MAX_FILE_SIZE_DISPLAY }),
      checkFileSize
    )
  });

  useEffect(() => {
    const setData = async () => {
      getCategories();
      if(selectedRow?.id) {
          const row = await getInfoContentById(selectedRow.id)
          row.tags = row?.tags.map(el => el.id);
          setInfoContentTmp(row)
        }
        else {
          setInfoContentTmp(initialState)
        }
      };
    setData();
  }, [selectedRow])

  const getInfoContentById = async(id) =>  {
    try {
      const res = await InfoContentAPIs.getInfoContentById(id, language);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  const getCategories = async() =>  {
    try {
      let res = await APIs.getQuestionCategories(language);
      setCategories(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const doSubmit = (values) => {
    if (formRef.current.isValid) {
      if(values?.id) {
        onEdit(getObjectToSave(values))
        onCancel()
      }
      else {
        onCreate(getObjectToSave(values))
        onCancel()
      }
    }
  }

  const getObjectToSave = (infoContent) => {
    const infoContentTmp = infoContent;
    infoContentTmp.status = infoContent.status?.id ? infoContent.status.id : infoContent.status;
    infoContentTmp.picture = infoContent?.picture ? infoContent?.picture : '';
    return infoContentTmp
  }

  const renderForm = (values, setFieldValue) => {
    return (
      <>
        <Row>
          <Col lg={6}>
            <FormTextField
              title={t('title')}
              name={"title"}
            />
          </Col>
          <Col>
            <FormSelectField
              name="status"
              title={t('status')}
              options={statusOptions(t)}
            />
          </Col>
          <Col lg={2} className="mt-4">
            <FormCheckbox
              title={t('send_notifications')}
              label={t('send_notifications')}
              name={"send_notifications"}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormTextField
              title={t('short_intro')}
              name={"short_intro"}
              as="textarea"
              rows={12}
            />
          </Col>
          <Col lg={5}>
            <ImageBrowser
              title={t('picture')}
              name={"picture"}
              preview={true}
              filename={values?.picture?.name ? values.picture.name : values?.picture}
              onChange={(event) => {
                setFieldValue("picture", event.currentTarget.files[0]);
              }}
            />
          </Col>
          <Col lg={1} style={{marginTop: "25px"}}>
            {values?.picture && <X className="custom-icon" color="red" size={20} 
              onClick={() => setFieldValue("picture", '')}/>}
          </Col>
        </Row>
        <Row className="mt-3">
        </Row>
        <Row>
          <Col>
            <FormSelectField
              title={t('tags')}
              name={"tags"}
              id={"tags"}
              options={categories?.map(el => {return({value: el.id, label: el.name})})}
              isMulti={true}
            />

          </Col>
        </Row>
        <Row>
          <Col lg={2} style={{alignSelf:"center", marginTop: "auto"}}>
            <FormCheckbox
              title={t('external')}
              label={t('external')}
              name={"external"}
            />
          </Col>
          <Col lg={4}>
            <FormTextField
              title={t('external_url')}
              name={"external_url"}
              disabled={!values?.external}
            />
          </Col>
          <Col lg={6}>
            {values?.average_rating ? <FormTextField
              title={t('average_rating')}
              name={"average_rating"}
              disabled={true}
            /> : <div className="mt-4">{t('no_rating_yet')}</div>}
          </Col> 
        </Row>
        <Row>
          <Col>
             <FormCheckbox
              title={t('show_in_browser')}
              label={t('show_in_browser')}
              name={"show_in_browser"}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormTextField
              title={t('qr_code')}
              name={"qr_code"}
            />
          </Col>
        </Row>
        {!values?.external &&
        <Row>
          <Col className="mt-3">
            <FormEditor
              title={t('content')}
              name={"content"}
              savedContent={infoContentTmp?.content}
              readonly={values?.external}
            />
          </Col>
        </Row>}
      </>
    )
  }

  return(
    <>
    <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={infoContentTmp}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
          handleSubmit,
          values,
          dirty,
          setFieldValue,
        }) => (
        <CustomModal
          title={selectedRow?.id ? t('edit_obj', {obj: 'info_content'}) : t('new_obj', {obj: 'info_content'})}
          content={
            <Form id="form-info-content">
              {renderForm(values, setFieldValue)}
            </Form>
          }
          contentStyle={{fontSize: "12px"}}
          onCancel={onCancel}
          onOK={() => handleSubmit()}
          okButtonText={t('buttons.save')}
          size={"xl"}
          modified={dirty}
        />
        )
      }
    </Formik>
    </>
  )

}
export default InfoContentDetails;
